import React from 'react';
// import IosShareIcon from '@mui/icons-material/IosShare';
import { Box, Typography } from '@mui/material';
import Navbar from '../layouts/Navbar';
import Footer from '../layouts/Footer';


const Contactus = () => {
    return (
        <>
            <Navbar />
            <div className='container-fluid mt-3'>
                <div className='row'>
                    <Box display='flex'>
                        <div className='col-md-6'>
                            <Box marginX='0' pt={0}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.6409875509416!2d85.3000028745354!3d27.69748892592786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb18507aaaaaab%3A0x8589e9d552290806!2sDakali%20Industrial%20Marketing%20Pte.%20Ltd.!5e0!3m2!1sen!2snp!4v1726895753929!5m2!1sen!2snp"
                                    title="contact-map" width="100%" height="630" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </Box>
                        </div>

                        <div className='col-md-6'>
                            <div className='row-contact-backgroud'>
                                <Box className='address text-white' width={'70%'} p={3} pt={3}>
                                    {/* <Typography variant='h6' sx={{ color: 'white' }}>
                                        Address
                                    </Typography> */}
                                    <Typography variant='h6'>
                                        Dakali Industrial Marketing Pvt. Ltd.
                                    </Typography>
                                    <Typography variant='h6'>
                                    <i className="bi bi-geo-alt-fill m-2"></i>
                                        Teku, Kathmandu-12
                                    </Typography>
                                    <Typography variant='h6'>
                                    <i className="bi bi-telephone-fill m-2"></i>
                                        Phone: 01-5340944
                                    </Typography>
                                    <Typography variant='h6'>
                                    <i className="bi bi-envelope-fill m-2"></i>
                                        Email: info@dakali.com.np
                                    </Typography>
                                    {/* <Typography variant='h6'>
                                        Website: www.dakali.com.np
                                    </Typography> */}


                                </Box>
                                <Box className='contact-form' width={'100%'} p={3} pt={1} margin={0}>
                                    <Typography variant='h6' sx={{ color: 'white' }}>
                                        Contact
                                    </Typography>


                                    <div class="mb-3">
                                        <label for="name" class="form-label" style={{ color: 'white' }}>Name</label>
                                        <input type="name" class="form-control" id="exampleFormControlInput1" placeholder="Name" style={{ color: 'black' }}/>
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label" style={{ color: 'white' }}>Email address </label>
                                        <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email" />
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label" style={{ color: 'white' }}>Feedback</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message" />
                                    </div>

                                    <div class="mb-3">
                                        <button type="button" class="btn btn-light">Submit</button>
                                        
                                    </div>
                                    


                                </Box>
                            </div>
                        </div>
                    </Box>
                </div>
            </div>







            <Footer />
        </>
    )
}

export default Contactus