import React from 'react'
import Navbar from '../layouts/Navbar'
import Footer from '../layouts/Footer'




const Team = () => {
    return (
        <>
        <Navbar/>
        {/* <div className='bg-body-secondary text-white mt-3 pt-1' style={{height: '10vh'}}> */}
          
            {/* <center><h1>OUR TEAM</h1></center> */}

            {/* </div> */}
            {/* <div className='row'>
            <div className="col-md-4">     
  <img src="./images/Our-team/MD.jpeg" className="rounded-circle radius-50%" alt="..."/>
  
    <h5 className="card-title">Card title</h5>
    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <button to="#" className="btn btn-primary">Read more...</button>
  </div>
  <div className="col-md-4">
  <img src="./images/Our-team/MD.jpeg" className="rounded-circle" alt="..."/>
    <h5 className="card-title">Card title</h5>
    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <button to="#" className="btn btn-primary">Read more...</button>
  </div>
  <div className=" col-md-4">
  <img src="./images/Our-team/MD.jpeg" className="rounded-circle" alt="..."/>
    <h5 className="card-title">Card title</h5>
    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <button to="#" className="btn btn-primary">Read more...</button>
  </div>
// </div> */}
<div className='card-group m-5 p-1'>
<div className="card border-0" style={{width: 256}}>
  <img src="./images/Our-team/MD.jpeg" className="team-card-img-top" alt="..."/>
  <div className="card-body">
    <center><h6 className="card-title fs-5">Mr. Kiran Prasad Shrestha</h6></center>
    <center><h6 className="card-title fs-6" style={{color: '#696969'}}>Managing Director</h6></center>
    <p className="card-text">
    Representing the third generation in the field, Mr. Kiran Prasad Shrestha leads Dakali Industrial Marketing Pvt. Ltd. as a dynamic and forward-
                                thinking entrepreneur. With over 20 years of industry experience, he brings a deep understanding of customer needs and a commitment
                                to delivering high-quality products.His extensive knowledge of the industry, combined with his technical expertise and operational
                                acumen, ensures that Dakali continues to meet and exceed the expectations of its clients</p>
    {/* <Link to="/teamdes" className="btn btn-primary">Read more..</Link> */}
  </div>
</div>
<div className="card border-0" style={{width: 256}}>
  <img src="./images/Our-team/FD.jpeg" className="team-card-img-top" alt="..."/>
  <div className="card-body">
  <center><h5 className="card-title fs-5">Ms. Smriti Shrestha</h5></center>
  <center><h5 className="card-title fs-6" style={{color: '#696969'}}>Finance Director</h5></center>
    <p className="card-text" style={{height:'20vh'}}>   Smriti Shrestha, an MBA graduate
                                from KUSOM, brings youthful
                                energy and a fresh perspective to
                                her role as Finance Director at the
                                company. With over two years of
                                experience in the trade department
                                at NMB Bank, she has a solid
                                foundation in financial
                                management and trade operations.
                                Her dynamic approach and
                                innovative thinking are
                                instrumental in driving the financial
                                strategies that support Dakali&#39;s
                                growth and success in the
                                competitive industrial sector.</p>
    {/* <Link to="#" className="btn btn-primary">Read more</Link> */}
  </div>
</div>
<div className="card border-0" style={{width: 256}}>
  <img src="./images/Our-team/TD.jpeg" className="team-card-img-top" alt="..."/>
  <div className="card-body">
  <center><h5 className="card-title fs-5">Mr. Sarthak Shrestha</h5></center>
  <center><h5 className="card-title fs-6" style={{color: '#696969'}}>Technical Director</h5></center>
    <p className="card-text" style={{height:'20vh'}}> As the Technical Director, Sarthak
                                Shrestha infuses the company with
                                fresh and innovative ideas.
                                Currently studying Chemical
                                Engineering at Kathmandu
                                University, he blends his academic
                                expertise with a strong passion for
                                modern, forward-looking solutions.
                                His youthful energy and
                                enthusiasm are key to keeping
                                Dakali at the forefront of industry

                                trends.</p>
    {/* <Link to="#" className="btn btn-primary">Read more</Link> */}
  </div>
</div>
</div>   
            
<Footer/>
        </>
    )
}

export default Team