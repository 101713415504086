import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './components/pages/Home'
import Aboutus from './components/pages/Aboutus'
import Team from './components/pages/Team'
import Contactus from './components/pages/Contactus'
import Products from './components/Products'
import Brands from './components/Brands'

const Myroutes = () => {
  return (
    <BrowserRouter>
    <Routes>
        <Route path='/' element = {<Home/>}/>
        <Route path='/aboutus' element = {<Aboutus/>}/>
        <Route path='/team' element = {<Team/>}/>
        <Route path='/product' element = {<Products/>}/>
        <Route path='/contact' element = {<Contactus/>}/>
        <Route path='/brands' element = {<Brands/>}/>

    </Routes>
    
    
    </BrowserRouter>
  )
}

export default Myroutes