import React from 'react'
import Navbar from './layouts/Navbar'
import Footer from './layouts/Footer'
import { Link } from 'react-router-dom'

const Brands = () => {
  return (
    <>
      <Navbar />
      {/* <p className="card-text text-center fs-2 fw-bold"><small className="text-body-secondary">Our Brands</small></p> */}
      {/* <h1>Our Brands Representation</h1> */}
      <div className="wrapper">
      

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4 p-1">
          <div className="col">
            <div className="card border-0">
              
              <Link to="https://www.clariant.com/en/Corporate"><img src='./images/Brand-images/Clariant Logo.jpg' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Atul Limited</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
            <Link to="https://www.indofil.com"><img src='./images/Brand-images/Indofil.png' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Britacel Silicones Limited</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
            <Link to="http://www.atul.co.in"><img src='./images/Brand-images/atul.png' alt='...' style={{width: '90px',height: '90px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Clariant Specialty Chemicals</small></p> */}
            </div>
          </div>
          


        </div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 p-1">
          <div className="col">
            <div className="card border-0">
            <Link to="https://jaychemical.com/"><img src='./images/Brand-images/jaychemical-logo.png' alt='...' style={{width: '120px',height: '120px'}} /></Link>
              
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Hangzhou Jihua Import and Export</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
              <Link to="https://www.thecatalystsgroup.com"><img src='./images/Brand-images/logo-catalysts.png' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">The Catalysts Group</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
            <Link to="http://sohandyechem.com/"><img src='./images/Brand-images/sohan.jpg' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Jay Chemicals</small></p> */}
            </div>
          </div>
         


        </div>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 p-1">
          <div className="col">
            <div className="card border-0">
            <Link to="https://www.cht.com/en/"><img src='./images/Brand-images/cht.PNG' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Sohan Industries</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
            <Link to="https://www.britacelsilicones.com"><img src='./images/Brand-images/britacel_logo.png' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Indofil Industries Limited</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
            <Link to="https://en.jihuadyes.com"><img src='./images/Brand-images/jihua.png' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">The CHT Group</small></p> */}
            </div>
          </div>





        </div>


      </div>

      <Footer />
    </>
  )
}

export default Brands