// import logo from './logo.svg';
import './App.css';
import Myroutes from './Myroutes';

function App() {
  return (
    <>
    
   <Myroutes/>
    </>
  );
}

export default App;
