import React from 'react'
import Navbar from '../layouts/Navbar'
import Footer from '../layouts/Footer'

const Aboutus = () => {
  return (
    <>
   
    <Navbar/>
<div className='container-fluid'>
{/* <p className="card-text text-center fs-2 fw-bold pt-5"><small className="text-body-dark">Dakali Industrial Marketing Pte. Ltd,</small></p> */}
    <div className="row m-4">
    {/* <div className='bg-body-secondary text-secondary mt-3 pt-1' style={{height: '10vh'}}>
                <center><h1>About Us</h1></center>
                </div> */}
                <div className='col-md-6 py-5'>
                <img src="./images/Carousel-images/2.jpeg" className="img-fluid about-image" alt="..." style={{width: '650px' ,height:'420px'}}/>

                </div>
                <div className='col-md-6 py-4'>
                <p style={{textAlign: 'justify'}}><strong>Dakali Industrial Marketing Pte. Ltd.</strong> proudly carries forward the legacy
                   of our family business, which began with a humble shop in Bangemuda, Kathmandu. For years, our family 
                   served the local community, building a reputation for trust and quality. In 2058 B.S., we took a 
                   significant step forward by officially establishing Dakali Industrial Marketing Pvt. Ltd. This 
                   transition marked the beginning of our journey to provide top-quality solutions to a broader range 
                   of industries across Nepal.</p>

                   <p style={{textAlign: 'justify'}}>Today, Dakali has grown into a trusted partner for numerous international 
                    brands, offering a comprehensive range of products and services to industries such as Textiles, Coating, Paper,
                     Breweries, Home & Personal Care throughout Nepal. Our experienced engineering team and knowledgeable product 
                     experts work closely with our clients, ensuring that every solution is tailored to meet their specific needs 
                     and exceed their expectations.</p>

                   <p style={{textAlign: 'justify'}}>Starting with a strong presence in the Kathmandu market, Dakali has 
                    successfully expanded its operations nationwide. Our unwavering commitment to ethical business practices and
                     a relentless focus on quality have earned us recognition as one of Nepal’s leading dealers in Dyestuffs, 
                     Pigments, Enzymes, Auxiliaries and other Chemicals. The trust and confidence of our customers are the 
                     pillars of our success, inspiring us to continually deliver excellence in every aspect of our business.</p>
                         </div>
            </div>
</div>
    <Footer/>
    
    </>
  )
}

export default Aboutus