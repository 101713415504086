
import React from 'react'
import { Link } from 'react-router-dom'

const Carousel = () => {
    return (
        <>

<div id="carouselExampleFade" className="carousel slide carousel-fade">
  <div className="carousel-inner">
    {/* <div className="carousel-item active">
      <img src="./images/Carousel-images/agro-chemicals-1.jpg" className="d-block w-100 carousel-image" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>First</h5>
        <p>Dakali Industrial Marketing Pte. Ltd.</p>
      </div>
    </div> */}
   
    <div className="carousel-item active">
      <img src="./images/Carousel-images/colors1.jpg" className="d-block w-100 carousel-image" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Second</h5>
        <Link to ='/aboutus'> Dakali Industrial Marketing Pte. Ltd.</Link>
       
      </div>
    </div>
    <div>
    <div className="carousel-item">
      <img src="./images/Carousel-images/1.jpeg" className="d-block w-100 carousel-image" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Second</h5>
        <Link to ='/aboutus'> Dakali Industrial Marketing Pte. Ltd.</Link>
      </div>
    </div>
    <div className="carousel-item">
      <img src="./images/Carousel-images/2.jpeg" className="d-block w-100 carousel-image" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Second</h5>
        <Link to ='/aboutus'> Dakali Industrial Marketing Pte. Ltd.</Link>
      </div>
    </div>
    <div className="carousel-item">
      <img src="./images/Carousel-images/3.jpeg" className="d-block w-100 carousel-image" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Second</h5>
        <Link to ='/aboutus'> Dakali Industrial Marketing Pte. Ltd.</Link>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</div>
</>
    )
}
export default Carousel