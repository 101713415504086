import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../layouts/Navbar';
import Footer from '../layouts/Footer';
import Carousel from '../Carousel';

// import Products from '../Products';


const Home = () => {
  return (
    <>
      <Navbar />
      <Carousel />


      {/* <Brands/> */}
      <p className="card-text text-center fs-2 fw-bold pt-5"><small className="text-body-dark">OUR BRANDS</small></p>
      <div className='wrapper'>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 p-1">
          <div className="col">
            <div className="card border-0">
            <Link to="https://www.clariant.com/en/Corporate"><img src='./images/Brand-images/Clariant Logo.jpg' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Atul Limited</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
            <Link to="https://www.indofil.com"><img src='./images/Brand-images/Indofil.png' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Britacel Silicones Limited</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
            <Link to="http://www.atul.co.in"><img src='./images/Brand-images/atul.png' alt='...' style={{width: '90px',height: '90px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Clariant Specialty Chemicals</small></p> */}
            </div>
          </div>
        </div>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 p-1">
          <div className="col">
            <div className="card border-0">
            <Link to="https://jaychemical.com/"><img src='./images/Brand-images/jaychemical-logo.png' alt='...' style={{width: '120px',height: '120px'}} /></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Hangzhou Jihua Import and Export</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
              <Link to="https://www.thecatalystsgroup.com"><img src='./images/Brand-images/logo-catalysts.png' alt='...' style={{ width: '120px', height: '120px' }} /></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">The Catalysts Group</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
            <Link to="http://sohandyechem.com/"><img src='./images/Brand-images/sohan.jpg' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Jay Chemicals</small></p> */}
            </div>
          </div>



        </div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 p-1">
          <div className="col">
            <div className="card border-0">
            <Link to="https://www.cht.com/en/"><img src='./images/Brand-images/cht.PNG' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Sohan Industries</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
            <Link to="https://www.britacelsilicones.com"><img src='./images/Brand-images/britacel_logo.png' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">Indofil Industries Limited</small></p> */}
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
            <Link to="https://en.jihuadyes.com"><img src='./images/Brand-images/jihua.png' alt='...' style={{width: '120px',height: '120px'}}/></Link>
              {/* <p className="card-text fs-4 fw-bold"><small className="text-body-secondary">The CHT Group</small></p> */}
            </div>
          </div>





        </div>
      </div>
      {/* ....... */}

 {/* Our Products*/}
 <p className="card-text text-center fs-2 fw-bold pt-5"><small className="text-body-dark">OUR PRODUCTS</small></p>
      <div className='container p-0'>
        <div className="row p-4 m-2">
          <div className="col-lg-6 p-1">
            <div className='circle'>
              {/* <center><img src='./images/Products-images/Industrial.jpeg' className='rounded-circle' alt = '...'/></center> */}
              <center ><h5>BY INDUSTRY</h5></center>
            </div>
            {/* <svg className="rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="./images/Products-images/Generic.png" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> */}
            {/* <img src="./images/Products-images/Generic.png" className="img-fluid rounded-circle" alt = ""/> */}
            {/* <h1 className="fw-normal">Heading</h1> */}
            {/* <p>Some representative placeholder content for the three columns of text below the carousel. This is the first column.</p> */}
            {/* <p><Link className="btn btn-secondary" to="#">View details &raquo;</Link></p> */}
            <nav className="navbar">

              <form className="fr" role="search">
                <input className="form-control me-2" type="search" placeholder="Type industry here" aria-label="Search" />
                {/* <button className="btn btn-outline-secondary" type="submit">Go</button> */}
              </form>

            </nav>
          </div>


          <div className="col-lg-6 p-1">
            <div className='circle'>
              {/* <center><img src='./images/Products-images/Generic1.jpeg' className='rounded-circle' alt='...' /></center> */}
              <center><h5>BY GENERIC NAME</h5></center>
            </div>
            {/* <svg className="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)" /></svg>
          <h1 className="fw-normal">Heading</h1>
          <p>And lastly this, the third column of representative placeholder content.</p>
          <p><Link className="btn btn-secondary" to="#">View details &raquo;</Link></p> */}
            <nav className="navbar">

              <form className="fr" role="search">
                <input className="form-control me-2" type="search" placeholder="Type generic name here" aria-label="Search" />
                {/* <button className="btn btn-outline-secondary" type="submit">Go</button> */}
              </form>

            </nav>
          </div>
        </div>
      </div>
      {/* ....... */}


      {/* Our Team */}
      <p className="card-text text-center fs-2 fw-bold pt-5"><small className="text-body-dark">OUR TEAM</small></p>
      
      <div className="row-team-backgroud">
        <div className='col-md-12'>
          <div className='card-group m-5 py-5'>


            <div className="card-body my-5 py-5">

              <Link to='/team'><center><h5 className="card-title fs-5 text-white text-decoration-none">Mr. Kiran Prasad Shrestha</h5></center></Link>
              <center><h6 className="card-title fs-6" style={{color: '#D3D3D3'}}>Managing Director</h6></center>
            </div>



            <div className="card-body my-5 py-5">
              <Link to='/team'><center><h5 className="card-title fs-5 text-white text-decoration-none">Mr. Sarthak Shrestha</h5></center></Link>
              <center><h6 className="card-title fs-6" style={{color: '#D3D3D3'}}>Technical Director</h6></center>
            </div>

            <div className="card-body my-5 py-5">
              <Link to='/team'><center><h5 className="card-title fs-5 text-white text-decoration-none">Ms. Smriti Shrestha</h5></center></Link>
              <center><h6 className="card-title fs-6" style={{color: '#D3D3D3'}}>Finance Director</h6></center>
            </div>


          </div></div>
      </div>

      {/*''''''''''  */}

     


      <Footer />
    </>
  )
}

export default Home